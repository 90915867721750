import React from 'react';
import { Link } from 'react-router-dom'

import './CallToAction.scss';

function CallToAction(props) {

	const classes = [ 'cta' ];
	if (props.className) {
		classes.push(props.className);
	}
	const { ctaLabel = 'BETTER OUTCOMES START HERE', ctaDestination =  '/contact-us/' } = props;
	// let ctaLabelArray = ['Better Outcomes', 'Start Here'];
	// if( props.ctaLabel ){
	// 	ctaLabelArray = props.ctaLabel;
	// }

	// const twoItemArray = ctaLabelArray.slice(0, 2);
	// if( ctaLabelArray.length > 2 ){
	// 	console.log( "The CTA label array passed into the CallToAction component was longer than required. Items beyond the first two will be ignored." );
	// }

	//const ctaDestination = props.ctaDestination ? props.ctaDestination : '/contact-us/';

	return (
		<div className={classes.join(' ')}>
			<Link to={ctaDestination} className="cta--link">{ctaLabel}</Link>
		</div>
	);
}
export default CallToAction;