import React from 'react';
import './Logo.scss';

function Logo(props) {

	const classes = [ 'brand-box' ];
	if (props.className) {
		classes.push(props.className);
	}

	return (
	<div className={classes.join(' ')}>
		<svg
			className="brand-logo"
			version="1.1"
			id="SWW-Layer-1"
			xmlns="http://www.w3.org/2000/svg"
			x="0px"
			y="0px"
			viewBox="0 0 225.4960938 212.0175781"
			>
			{/* <path className="logo-TM" d="M206.6699219,203.8085938h-3.0244141v-1.6806641h7.96875v1.6806641h-3.0234375v8.2089844h-1.9208984 V203.8085938z M223.0400391,204.2402344h-0.0488281l-2.8554688,7.7773438h-1.4882813l-2.8564453-7.7773438h-0.0478516v7.7773438 h-1.9199219v-9.8896484h3.0957031l2.4726563,6.7451172l2.4716797-6.7451172h3.0966797v9.8896484h-1.9199219V204.2402344z"/> */}
			<path className="logo-W" d="M138.3017578,50.2841797v100.7607422l8.5390625-4.9296875V45.3544922 l34.5888672-19.9697266v90.4306641l0.9746094-0.5625c3.5605469-2.0556641,8.5234375-9.2314453,8.5234375-20.7744141V19.9013672 L225.3974609,0l0.0986328,96.0078125c0,44.5390625-14.5742188,53.7060547-27.4306641,61.1132813l-94.8984375,54.7744141V70.5693359 L138.3017578,50.2841797z"/>
			<path className="logo-S" d="M138.2636719,90.8330078l-79.3017578,45.7841797 c-2.9160156,1.6845703-7.9589844,4.5957031-3.7734375,7.0126953l4.3046875,2.4853516l87.2617188-50.3808594l47.59375,27.4785156 c19.2978516,11.1425781,23.453125,22.4003906,3.703125,33.9003906 c-2.8007813,1.6308594-94.8847656,54.7822266-94.8847656,54.7822266l-35.1347656-20.2851563l87.5068359-50.5214844 c5.3173828-3.0703125,6.9404297-5.2578125,3.015625-7.234375l-4.6132813-2.7207031l-95.1582031,54.9482422l-37.75-21.7929688 c-33.0498047-19.0830078-21.8964844-33.6591797-2.3388672-44.9492188 c18.5214844-10.6923828,84.4726563-48.7705078,84.4726563-48.7705078L138.2636719,90.8330078z"/>
			<path className="logo-overlap" d="M138.265625,100.6357422l-35.1347656,20.2851563v39.5527344l35.1347656-20.2880859V100.6357422z  M194.3125,123.234375l-12.8662109-7.4287109l-0.0537109,0.0302734v-0.0605469l-34.5878906-19.9707031v39.4501953 l7.0996094-4.1005859l4.6142578,2.7216797c3.9248047,1.9765625,2.3017578,4.1630859-3.0166016,7.2333984l-8.6972656,5.0224609 v0.0039063l-8.5390625,4.9296875v-0.0039063l-35.1347656,20.2851563v40.5693359l0.0361328-0.0205078 c1.9619141-1.1328125,92.078125-53.1484375,94.8486328-54.7607422 C217.765625,145.6347656,213.6103516,134.3759766,194.3125,123.234375z M103.1308594,70.5908203v40.5263672l35.0966797-20.2626953 L103.1308594,70.5908203z"/>
		</svg>
		{ props.type!=='device' && <div className="brand-masthead">
			<div className="brand-masthead-primary">Stephen Woolley</div>
			<div className="brand-masthead-secondary">OCCUPATIONAL THERAPY</div>
			</div>
		}
	</div>
	);
}
export default Logo;