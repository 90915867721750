import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../components/Logo';
import ButtonLink from '../../components/Button/ButtonLink';
import Icon from '../Icon/Icon';

import './Footer.scss';
function Footer(props) {

	const classes = [ 'footer footer-bg--city pt-0' ];
	if (props.className) {
		classes.push(props.className);
	}

	return (
		<section className={classes.join(' ')}>
			<div className="divider-up--top">
				<svg width="100%" height="100px" viewBox="0 0 1280 140" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
					<g fill="#FFFFFF">
						<path d="M640 5 	L0 139	L 0 0	h1280	L1280 139	z" fillOpacity=".5"/>
						<path d="M640 5 	L0 97	L 0 0	h1280	L1280 97	z"/>
					</g>
				</svg>
			</div>
			<div className="container">
				<div className="container-left">
					<Logo type="full" className="" />
					<div>
						<p>Stephen Woolley Occupational Therapy provides expert services in the field of Occupational Therapy, Workplace Assessments, Medico-Legal Reports and more.
							Our team have over 17 years' experience in both the public and private health care sectors.</p>
						<h5 className="business-details">Clinic <span className="subtle">(by appointment)</span></h5>
						<p>Charles Street Clinic, 89 Charles Street,  Seddon VIC 3011</p>
						<h5 className="business-details">Postal Address</h5>
						<p>PO Box 364, SEDDON VIC 3011</p>
					</div>
				</div>
				<div className="container-right">
					<div className="footer-contact-box">
						<h5>Get in touch</h5>
						<ButtonLink to="/contact-us/" className="rev" icon="comment">Message Us</ButtonLink>
						<ButtonLink to="https://www.linkedin.com/in/stephen-woolley-09775594/" className="rev" icon="linked-in" target="_exSWW">Linked In</ButtonLink>
						<ButtonLink to="/contact-us/" className="rev" icon="map-marker">View Map</ButtonLink>
						<ButtonLink to="tel:03-9687-7153" className="rev" icon="phone">03 9687 7153</ButtonLink>
					</div>
					<div className="footer-links-box">
						<h5>Quick Links</h5>
						<ul className="quick-links">
							<li><Link to="/sitemap/" label="View the sitemap"><Icon type='sitemap' />Sitemap</Link></li>
							<li><Link to="/privacy-policy/" label="View the privacy policy"><Icon type='privacy' />Privacy Policy</Link></li>
							<li><Link to="/contact-us/" label="Link to contact us form"><Icon type='email' />Contact Us</Link></li>
							<li><a href="https://www.ahpra.gov.au/" target="_exSWW" label="Verify our registration with AHPRA"><Icon type='link-external' />AHPRA Verification</a></li>
						</ul>
					</div>
				</div>
			</div>
			<div className="container disclaimer">
				<p>Stephen&nbsp;Woolley&nbsp;Occupational&nbsp;Therapy </p>
				<p>ABN&nbsp;81&nbsp;166&nbsp;483&nbsp;549</p>
				<p>&copy;&nbsp;Content is protected by copyright and may not be used or distributed without written consent.</p>
				<p>Icons under licence from <a href="https://fontawesome.com/license" target="_exSWW">Font Awesome</a>.</p>
			</div>
		</section>
	);
}
export default Footer;