import { useEffect } from "react";
import ReactGA from "react-ga";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
// See possible alternative to same package: https://github.com/react-ga/react-ga/issues/122#issuecomment-526944210

function sendPageView(location) {
  ReactGA.set({page: location.pathname});
  ReactGA.pageview(location.pathname);
}

function GAListener({children, trackingId, history}) {
  useEffect(() => {
    ReactGA.initialize(trackingId);
    sendPageView(history.location);
    return history.listen(sendPageView);
  }, [trackingId, history]);

  return children;
}

GAListener.propTypes = {
  children: PropTypes.node,
  trackingId: PropTypes.string,
  history: PropTypes.shape({
    listen: PropTypes.func,
  }),
};

export default withRouter(GAListener);