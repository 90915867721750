import React from 'react';
import { Helmet } from "react-helmet-async";
import Banner from '../../components/Banner/Banner.jsx';
//import LHMap from '../../components/Map/LHMap.js'
import ContactUsForm 	from '../../components/ContactUsForm/ContactUsForm';
import ContactUsDetails from '../../components/ContactUsDetails/ContactUsDetails';

import './Referrals.scss';


function ContactUs() {
	return (
		<div className="main contact-us-page">
			<Helmet>
				<title>Submit a Referral | Stephen Woolley Occupational Therapy</title>
        		<meta name="description" content="Submit a referral using our online form or call (03) 9687 7153." />
                <link rel="canonical" href="https://stephenwoolley/referrals/" />
			</Helmet>
			<Banner name="head" className="neutral-ultra-light-bg" />
			{/* <LHMap /> */}
			<section>
				<div className="container">
					<div className="container-left">
						<ContactUsForm formType="sww-referral" />
					</div>
					<div className="container-right">
						<h4>Referral for Stephen Woolley Occupational Therapy</h4>
						<h1>Referral Request Form</h1>
						<p>Please be advised that, following receipt of the below Referral Service Request Form, a detailed costing for the service will be provided within 24 hours.</p>

						<ContactUsDetails />

					</div>
				</div>
			</section>



		{ process.env.NODE_ENV && process.env.NODE_ENV === 'development' &&
			<section className="solid-white marker">
				<div className="container">

				</div>
			</section>
		}


		</div>
	);
}

export default ContactUs;