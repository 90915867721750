import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import GAListener from './utils/GAListener';
import ScrollToTop from './utils/ScrollToTop';

import App from './containers/App';

ReactDOM.render(
	<BrowserRouter>
		<HelmetProvider>
			<GAListener trackingId="UA-150000001-1">
				<ScrollToTop>
					<App />
				</ScrollToTop>
			</GAListener>
		</HelmetProvider>
	</BrowserRouter>,
	document.getElementById('root')
);
