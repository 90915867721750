import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import FormFieldMessage from '../FormFieldMessage/FormFieldMessage'
import ReCAPTCHA from "react-google-recaptcha";

import './ContactUsForm.scss';

const validationSchemaBasic = Yup.object().shape({
	firstName: Yup.string().min(1, "Must enter at least one letter.").max(255, "Must be shorter than 255 characters").required("Must enter a First Name"),
	lastName: Yup.string().min(1, "Must enter at least one letter.").max(255, "Must be shorter than 255 characters").required("Must enter a Last Name"),
	emailAddress: Yup.string().email("Must be a valid email address.").max(255, "Must be shorter than 255 characters").required("Must enter an Email Address"),
	message: Yup.string().min(10, "Must enter at least ten letters.").max(1000, "Must be shorter than 1000 characters").required("Must enter a Message"),
	phone: Yup.string().max(35, "Must be shorter than 35 characters").required("Must enter a Phone number"),
	subject: Yup.string().max(155, "Must be shorter than 155 characters"),
	reCAPTCHAgrc: Yup.string().min(3, "Please complete the robot check").required("Please complete the robot check")
});

const validationSchemaSwwReferral = Yup.object().shape({
	firstName: Yup.string().min(1, "Must enter at least one letter.").max(255, "Must be shorter than 255 characters").required("Must enter a First Name"),
	lastName: Yup.string().min(1, "Must enter at least one letter.").max(255, "Must be shorter than 255 characters").required("Must enter a Last Name"),
	emailAddress: Yup.string().email("Must be a valid email address.").max(255, "Must be shorter than 255 characters").required("Must enter an Email Address"),
	message: Yup.string().min(10, "Must enter at least ten letters.").max(1000, "Must be shorter than 1000 characters").required("Must enter a Message"),
	phone: Yup.string().max(35, "Must be shorter than 35 characters").required("Must enter a Phone number"),
	referralFor: Yup.string().min(3, "Please select a 'Referral for' option").required("Please select a 'Referral for' option"),
	reCAPTCHAgrc: Yup.string().min(3, "Please complete the robot check").required("Please complete the robot check")
});


export default function ContactUsForm (props) {
	const [ formSubmitSuccess, setFormSubmitSuccess ] = useState(false);	// these two fields
	const [ formSubmitFail, setFormSubmitFail ] = useState(false);			// these two fields :: jointly indicate an attempt at form submission
	const { formType = 'basic' } = props;									// formType [ 'basic' | 'sww-referral' ]
	const validationSchema = formType === 'sww-referral' ?  validationSchemaSwwReferral : validationSchemaBasic;
	const recaptchaRef = React.createRef();

	function reCaptchaOnExpired(value) {
		console.log("EXPIRED reCAPTCHA reset");
		recaptchaRef.current.reset();
	}

	function reCaptchaOnChange(value) {
		// console.log("TB ==============================================================================#",document.getElementsByName('g-recaptcha-response').length);
		// console.log("GRC");
		// console.log(value);
		// console.log("Name");
		// console.log(document.getElementsByName('g-recaptcha-response')[0].value);
		if(value.length > 30) {
			console.log("GRC Ready");
		} else {
			console.log("GRC Not Ready");
		}
	}

	return (
		<Formik
			initialValues={{
				firstName: "",
				lastName: "",
				emailAddress: "",
				company: "",
				address: "",
				message: "",
				phone: "",
				subject: "",
				referralFor: "",
				"g-recaptcha-response": '',
				reCAPTCHAgrc: ""
			}}
			validationSchema={validationSchema}
			onSubmit={ (values, {setSubmitting, resetForm}) => {
				setSubmitting(true);
				setFormSubmitSuccess(false);
				setFormSubmitFail(false);
				// Prepare the form data using the keys expected by the API
				const formData = new FormData();
				formData.append('firstname', 			values.firstName);
				formData.append('lastName', 			values.lastName);
				formData.append('email', 				values.emailAddress);
				formData.append('phone', 				values.phone);
				if(formType === 'sww-referral') formData.append('company', 		values.company);
				if(formType === 'sww-referral') formData.append('address', 		values.address);
				if(formType === 'sww-referral') formData.append('referralFor', 	values.referralFor);
				if(formType !== 'sww-referral') formData.append('subject', 		values.subject);
				formData.append('message', 				values.message);
				//formData.append('g-recaptcha-response', document.getElementsByName('g-recaptcha-response')[0].value);	// hmmmm.    :/
				formData.append('g-recaptcha-response', values.reCAPTCHAgrc);
				formData.append('formType', 			document.getElementById('formType').value);						// hmmmm. :/


				// see: https://developers.google.com/web/ilt/pwa/working-with-the-fetch-api
				// and: https://docs.microsoft.com/en-us/iis/extensions/cors-module/cors-module-configuration-reference
				let apiAddress = undefined;
				if( process.env.REACT_APP_API_CODEBASE ) {
					apiAddress = process.env.REACT_APP_API_CODEBASE    + 'sendContactForm';
				}
				if(!apiAddress) {
					console.log('API could not be resolved. Check environment variables');
					setFormSubmitFail(true);
				}
				
				fetch( apiAddress, {
					method: "POST",
					body: formData
				})
				.then(resp => {
					if(!resp.ok || resp.status >= 400) {	// could be any 40* or 50* errors.
						throw new Error(resp.statusText);	// throw to the catch and resolve/reject.
					}
					return resp.json();						// parse the JSON for the next promise
				})
				.then(respJSON => {
					//console.log('Success!',respJSON);		//example respJSON {e: "DEV", response: "success", dt: "November, 29 2019 08:16:58", errorMessage: "", mss: "S"}
					if(respJSON.response === 'success') {
						setFormSubmitSuccess(true);
						resetForm();
					} else {
						setFormSubmitFail(true);
					}
					window.grecaptcha.reset();
				})
				.catch(function(error) {
					setFormSubmitFail(true);
				});
				setSubmitting(false);
			}}
			>
			{ ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
			<form onSubmit={handleSubmit}>
				<input id="formType" name="formType" type="hidden" value={formType} />
				<div className="input-row--group">
					<div className="input-row">
						<label className="required" htmlFor="firstName">First Name</label>
						<input
							type="text"
							name="firstName"
							id="firstName"
							className={ touched.firstName && errors.firstName ? "has-error" : null }
							placeholder="Given Name / First Name"
							onChange={handleChange}
							value={values.firstName}
							onBlur={handleBlur}
							/>
						<FormFieldMessage touched={touched.firstName} message={errors.firstName} isError={touched.firstName && errors.firstName && true} />
					</div>
					<div className="input-row">
						<label className="required" htmlFor="lastName">Last Name</label>
						<input
							type="text"
							name="lastName"
							id="lastName"
							className={ touched.lastName && errors.lastName ? "has-error" : null }
							placeholder="Family Name / Surname"
							onChange={handleChange}
							value={values.lastName}
							onBlur={handleBlur}
							/>
						<FormFieldMessage touched={touched.lastName} message={errors.lastName} isError={touched.lastName && errors.lastName && true} />
					</div>
				</div>
				<div className="input-row--group">
					<div className="input-row">
						<label className="required" htmlFor="emailAddress">Email</label>
						<input
							type="email"
							name="emailAddress"
							id="emailAddress"
							className={ touched.emailAddress && errors.emailAddress ? "has-error" : null }
							placeholder="Your Email Address"
							onChange={handleChange}
							value={values.emailAddress}
							onBlur={handleBlur}
							/>
						<FormFieldMessage touched={touched.emailAddress} message={errors.emailAddress} isError={touched.emailAddress && errors.emailAddress && true} />
					</div>
					<div className="input-row">
						<label className="required" htmlFor="phone">Phone Number</label>
						<input
							type="text"
							name="phone"
							id="phone"
							className={ touched.phone && errors.phone ? "has-error" : null }
							placeholder="Preferred Phone Number"
							onChange={handleChange}
							value={values.phone}
							onBlur={handleBlur}
							/>
						<FormFieldMessage touched={touched.phone} message={errors.phone} isError={touched.phone && errors.phone && true} />
					</div>
				</div>

				{ formType === 'sww-referral' && <div className="input-row">
					<label className="" htmlFor="company">Company</label>
					<input
						type="text"
						name="company"
						id="company"
						className={ touched.company && errors.company ? "has-error" : null }
						placeholder="Company Name"
						onChange={handleChange}
						value={values.company}
						onBlur={handleBlur}
						/>
					<FormFieldMessage touched={touched.company} message={errors.company} isError={touched.company && errors.company && true} />
				</div>}

				{ formType === 'sww-referral' && <div className="input-row">
					<label className="" htmlFor="address">Address</label>
					<input
						type="text"
						name="address"
						id="address"
						className={ touched.address && errors.address ? "has-error" : null }
						placeholder="Full address including suburb, postcode and state"
						onChange={handleChange}
						value={values.address}
						onBlur={handleBlur}
						/>
					<FormFieldMessage touched={touched.address} message={errors.address} isError={touched.address && errors.address && true} />
				</div>}

				{ formType === 'sww-referral' && <div className="input-row">
					<label className="required" htmlFor="referralFor">Referral for</label>
					<select
						className="form-control simpleSelect"
						name="referralFor"
						id="referralFor"
						value={values.referralFor}
						onChange={handleChange}
						onBlur={handleBlur}
						>
						<option value="">Choose your referral type.</option>
						<option value="Activities of Daily Living (ADL) Assessment">Activities of Daily Living (ADL) Assessment</option>
						<option value="Early Intervention and Injury Prevention">Early Intervention and Injury Prevention</option>
						<option value="Ergonomic Assessment">Ergonomic Assessment</option>
						<option value="Ergonomic Training">Ergonomic Training</option>
						<option value="Functional Capacity Evaluation (WorkHab)">Functional Capacity Evaluation (WorkHab)</option>
						<option value="Life Insurance Assessment">Life Insurance Assessment</option>
						<option value="Manual Handling Training">Manual Handling Training</option>
						<option value="Medico Legal Assessment">Medico Legal Assessment</option>
						<option value="Pre-Employment Medical Assessment">Pre-Employment Medical Assessment</option>
						<option value="Return to Work Services">Return to Work Services</option>
						<option value="Worksite Assessment/Job Task Analysis">Worksite Assessment/Job Task Analysis</option>
						<option value="Other">Other</option>
					</select>
					<FormFieldMessage touched={touched.referralFor} message={errors.referralFor} isError={touched.referralFor && errors.referralFor && true} />
				</div>}

				{ formType !== 'sww-referral' && <div className="input-row">
					<label className="" htmlFor="subject">Subject</label>
					<input
						type="text"
						name="subject"
						id="subject"
						className={ touched.subject && errors.subject ? "has-error" : null }
						placeholder="Message Subject (optional)"
						onChange={handleChange}
						value={values.subject}
						onBlur={handleBlur}
						/>
					<FormFieldMessage touched={touched.subject} message={errors.subject} isError={touched.subject && errors.subject && true} />
				</div>}

				<div className="input-row">
					<label className="required" htmlFor="message">Message</label>
					<textarea
						rows="5"
						name="message"
						id="message"
						className={ touched.message && errors.message ? "has-error" : null }
						placeholder="How can we help you?"
						onChange={handleChange}
						onBlur={handleBlur}
						value={values.message}
						></textarea>
					<FormFieldMessage touched={touched.message} message={errors.message} isError={touched.message && errors.message && true} />
				</div>

				<div className="input-row">
					<ReCAPTCHA
						ref={recaptchaRef}
						sitekey="6LejPb4UAAAAAJ_2W8p20P8d_qYlvNUmWT-LqAqM"
						onChange={e => {
							reCaptchaOnChange(e)
							setFieldValue('reCAPTCHAgrc', e)
						}}
						onExpired={e => {
							reCaptchaOnExpired(e)
							setFieldValue('reCAPTCHAgrc', '')
						}}
						/>
					{/* <FormFieldMessage touched={grcTouched} message={!grc && ' ==== ONE==== Please verify you are not a robot'} isError={!grc} /> */}
					{/* <FormFieldMessage touched={touched['g-recaptcha-response']} message={' ==== TWO==== Please verify you are not a robot.'} isError={errors['g-recaptcha-response']} />*/}
					{/* ADD TO type="" below for visual check -=- process.env.NODE_ENV && process.env.NODE_ENV === 'development' ? 'text' : 'hidden'*/}
					<input
						type="hidden"
						id="reCAPTCHAgrc"
						name="reCAPTCHAgrc"
						value={values.reCAPTCHAgrc}
						onChange={handleChange}
						onBlur={handleBlur}
						></input>
					<FormFieldMessage touched={touched.reCAPTCHAgrc} message={errors.reCAPTCHAgrc} isError={errors.reCAPTCHAgrc} />
				</div>

				<div className="input-row">
					<button
						className={( Object.keys(errors).length > 0 || Object.keys(touched).length === 0 /* || !grc */) ? 'disabledButton' : ''}
						type="submit"
						disabled={isSubmitting /*|| Object.keys(errors).length > 0*/}
						>
						{(isSubmitting || Object.keys(errors).length > 0/* || !grc*/) ? 'Check Required Fields' : 'Send Message'}
					</button>
				</div>
				<div className={`form-submit-result-message   ${formSubmitSuccess && 'submit-success'} ${formSubmitFail && 'submit-fail'}`}>
					{formSubmitSuccess && 'Your message has been sent.'}
					{formSubmitFail && 'Hmm, we had trouble sending your message.'}
				</div>

				{/* <pre>{JSON.stringify(values, null, 2)}</pre> */}

			</form>

			)}
		</Formik>
	)
}