import React from 'react';
import { Helmet } from "react-helmet-async";
import Banner from '../../components/Banner/Banner.jsx';
import LHMap from '../../components/Map/LHMap.js'
import ContactUsForm from '../../components/ContactUsForm/ContactUsForm';
import ContactUsDetails from '../../components/ContactUsDetails/ContactUsDetails';


import './ContactUs.scss';


function ContactUs() {
	return (
		<div className="main contact-us-page">
			<Helmet>
				<title>Talk to a Melbourne Occupational Therapist | Stephen Woolley OT</title>
        		<meta name="description" content="If you need help with an Occupation Therapy matter in Melbourne, talk to one of our expert team members. Contacts us via our website, direct email, or call (03) 9687 7153." />
                <link rel="canonical" href="https://stephenwoolley/contact-us/" />
			</Helmet>
			<Banner name="head" className="neutral-ultra-light-bg" />
			<LHMap />
			<section>
				<div className="container">
					<div className="container-left">
						<ContactUsForm />
					</div>
					<div className="container-right">
						<h4>Contact Stephen Woolley Occupational Therapy Melbourne</h4>
						<h1>Ask an Occupational Therapist</h1>
						<p>If you need an experienced Occupational Therapist in Melbourne, touch base and
							one of our friendly team will listen to your needs and help you find a path forward.</p>
							<ContactUsDetails />
					</div>
				</div>
			</section>



		{ process.env.NODE_ENV && process.env.NODE_ENV === 'development' &&
			<section className="solid-white marker">
				<div className="container">

				</div>
			</section>
		}


		</div>
	);
}

export default ContactUs;