import React from 'react';
import { Helmet } from "react-helmet-async";
import { Link } from 'react-router-dom'
import Banner from '../../components/Banner/Banner.jsx';

//import './Sitemap.scss';

function Sitemap() {
	console.log('test');
	return (
		<div className="main">
			<Helmet>
				<title>Sitemap | Stephen Woolley Occupational Therapy</title>
        		<meta name="description" content="Melbourne Occupational Therapy | Stephen Woolley" />
                <link rel="canonical" href="https://stephenwoolley.com.au/sitemap/" />
			</Helmet>
			<Banner name="head" className="neutral-ultra-light-bg" />


			<section className="white-bg collapse">
				<div className="section-heading-box bound container">
					<h1>Sitemap</h1>
					<h2>Links to everything on our website.</h2>
				</div>
				<div className="container">

					<div className="container-left">
						<Link to="/">Homepage</Link>
						<Link to="/services/">Services</Link>
						<Link to="/medico-legal-services/">Medico Legal Services</Link>
						<Link to="/employment-services/">Employment Services</Link>
						<Link to="/about-us/">About Us</Link>
						<Link to="/referrals/">Referral Request Form</Link>
						<Link to="/contact-us/">Contact Us Form and Map</Link>
						<Link to="/privacy-policy/">Privacy Policy</Link>
						<Link to="/sitemap/">Sitemap (you are here now)</Link>
					</div>

				</div>
			</section>
		</div>
	);
}

export default Sitemap;