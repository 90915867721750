import React from 'react';
import { ReactComponent as IconBuildingOL } from "../../svg/icon-building-ol.svg";
import { ReactComponent as IconLayers } from "../../svg/icon-layers.svg";
//import { ReactComponent as IconUser } from "../../svg/icon-user.svg";
import { ReactComponent as IconStore } from "../../svg/icon-store.svg";
import { ReactComponent as IconCarCrash } from "../../svg/icon-car-crash.svg";
import { ReactComponent as IconAmbulance } from "../../svg/icon-ambulance.svg";
import { ReactComponent as IconInjury } from "../../svg/icon-injury.svg";
import { ReactComponent as IconBan } from "../../svg/icon-ban.svg";
//import { ReactComponent as IconElderly } from "../../svg/icon-elderly.svg";
import { ReactComponent as IconBook } from "../../svg/icon-book.svg";
import { ReactComponent as IconHospital } from "../../svg/icon-hospital.svg";
import { ReactComponent as IconCourt } from "../../svg/icon-court.svg";
import { ReactComponent as IconUserCheck } from "../../svg/icon-user-check.svg";
import { ReactComponent as IconHealthCheck } from "../../svg/icon-health-check.svg";
//import { ReactComponent as IconBalloon } from "../../svg/icon-balloon.svg";
import { ReactComponent as IconWind } from "../../svg/icon-wind.svg";
import { ReactComponent as IconErgonomic } from "../../svg/icon-ergonomic.svg";
import { ReactComponent as IconMedicalAssessment } from "../../svg/icon-medical-assessment.svg";
import { ReactComponent as IconOpenBox } from "../../svg/icon-open-box.svg";
import { ReactComponent as IconMonitor } from "../../svg/icon-monitor.svg";
import { ReactComponent as IconMedicalNotes } from "../../svg/icon-medical-notes.svg";
import "./CardStyles.scss";

function Cards(props) {

	const cardDeck = props.cardDeck;

	const cardsContent = cardDeck.length ? cardDeck.map(card =>
		<div className="card" key={card.icon}>
			<div className="icon-box">
				{ card.icon === 'layers' &&<IconLayers /> }
				{ card.icon === 'building' &&<IconBuildingOL /> }
				{/* card.icon === 'user' &&<IconUser /> */}
				{ card.icon === 'store' &&<IconStore /> }
				{ card.icon === 'car-crash' &&<IconCarCrash /> }
				{ card.icon === 'ambulance' &&<IconAmbulance /> }
				{ card.icon === 'injury' &&<IconInjury /> }
				{ card.icon === 'ban' &&<IconBan /> }
				{/* card.icon === 'elderly' &&<IconElderly /> */}
				{ card.icon === 'book' &&<IconBook /> }
				{ card.icon === 'hospital' &&<IconHospital /> }
				{ card.icon === 'court' &&<IconCourt /> }
				{ card.icon === 'user-check' &&<IconUserCheck /> }
				{ card.icon === 'health-check' &&<IconHealthCheck /> }
				{/* card.icon === 'balloon' &&<IconBalloon /> */}
				{ card.icon === 'wind' &&<IconWind /> }
				{ card.icon === 'ergonomic' &&<IconErgonomic /> }
				{ card.icon === 'medical-assessment' &&<IconMedicalAssessment /> }
				{ card.icon === 'open-box' &&<IconOpenBox /> }
				{ card.icon === 'monitor' &&<IconMonitor /> }
				{ card.icon === 'medical-notes' &&<IconMedicalNotes /> }
			</div>
			<div className="text-box">
				<h2>{card.heading}</h2>
				<p>{card.para}</p>
			</div>
		</div>
	) : '...';






	return (
		<div className=" cards cards-h">
			{cardsContent}
		</div>
	);
}

export default Cards;