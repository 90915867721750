import React from 'react';
import Icon from '../../components/Icon/Icon';

export default function ContactUsDetails() {
	return (
		<div className="contact-details-box">
			<div className="contact-detail-box">
				<Icon type="building-ol" />
				<div className="contact-detail-text-box">
					<h3>Clinic <span>(by appointment)</span></h3>
					<p><a className="link-only" target="_exSWW" href={`https://goo.gl/maps/TrDQDwFegcRpYZrM8`}>89 Charles Street, Seddon VIC 3011</a></p>
				</div>
			</div>
			<div className="contact-detail-box">
				<Icon type="phone-ol" />
				<div className="contact-detail-text-box">
					<h3>Telephone</h3>
					<p><a className="link-only" href={`tel:(03) 9687 7153`}>(03) 9687 7153</a></p>
				</div>
			</div>
			<div className="contact-detail-box">
				<Icon type="email-ol" />
				<div className="contact-detail-text-box">
					<h3>Direct Email</h3>
					<p><a className="link-only" href={'mailto:info@stephenwoolley.com.au'}>info@stephenwoolley.com.au</a></p>
				</div>
			</div>
			<div className="contact-detail-box">
				<Icon type="linked-in" className="self-hover" />
				<div className="contact-detail-text-box">
					<h3>LinkedIn</h3>
					<p><a className="link-only" target="_exSWW" href={'https://www.linkedin.com/in/stephen-woolley-09775594/'}>Connect via LinkedIn</a></p>
				</div>
			</div>
			{/* <div className="contact-detail-box">
				<Icon type="facebook" className="self-hover" />
				<div className="contact-detail-text-box">
					<h3>Facebook</h3>
					<p><a className="link-only" target="_exSWW" href={'https://www.linkedin.com/in/stephen-woolley-09775594/'}>Like us on facebook</a></p>
				</div>
			</div> */}
		</div>

	);
};