import React from 'react';
import { Helmet } from "react-helmet-async";
import Banner from '../../components/Banner/Banner.jsx';
import Teasers from '../../components/Teasers/Teasers.js';
//import './Services.css';

function Services() {
	return (
		<div className="main">
			<Helmet>
				<title>Services | Stephen Woolley Occupational Therapy</title>
				<meta name="description" content="Melbourne Occupational Therapy | Stephen Woolley" />
				<link rel="canonical" href="https://stephenwoolley/services/" />
			</Helmet>

			<Banner name="head" className="neutral-ultra-light-bg" />

			<section className="white-bg collapse">
				<div className="section-heading-box bound container">
					<h1>Medico Legal and Employment Services</h1>
					{/* <h2><span>Reliable</span> and <span>In-Depth</span> Medico-Legal Reports delivered on-time.</h2> */}
					<h2>Choose an option below to see more detail about our <span>Medico Legal</span> and <span>Employment services</span></h2>
				</div>
			</section>

			<Teasers serviceSubMenu={true} />

			{/* <section className="neutral-ultra-light-bg">
				<div className="container">
					<div className="container-left">
						<h3>Services</h3>
						<br />List all services.
						<br />Link to Referrals.
					</div>
				</div>
			</section> */}
		</div>
	);
}

export default Services;