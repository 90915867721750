import React, { useState, useEffect } from 'react';

import { Link, withRouter } from 'react-router-dom'
import Logo from '../Logo';
import HamburgerMenu from '../HamburgerMenu/HamburgerMenu';
import NavBar from '../NavBar/NavBar';
import './Header.scss';
// see https://github.com/reactjs/react-transition-group/tree/v1-stable for future transitions

function Header(props) {

	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const pn = props.location.pathname;

	useEffect(() => {
        props.history.listen(() => {
            setIsMenuOpen(false);
        });
	});

	function handleMenuToggle() {
		isMenuOpen ? setIsMenuOpen(false) : setIsMenuOpen(true);
	}

	const menuContent = (
		<div>
			<div>
				<ul>
					{/* {pn !== '/' && <li className=''><Link to='/'>Home</Link></li>} */}
					<li className={pn === '/services/' || pn === '/medico-legal-services/' || pn === '/employment-services/' ? 'active' : ''}><Link to='/services/'>Services</Link>
						<ul className="sub-menu sub-menu--services">
							<li className={pn === '/medico-legal-services/' ? 'active' : ''}><Link to='/medico-legal-services/'>Medico Legal Services</Link></li>
							<li className={pn === '/employment-services/' ? 'active' : ''}><Link to='/employment-services/'>Employment Services</Link></li>
						</ul>
					</li>
					<li className={pn === '/about-us/' ? 'active' : ''}><Link to='/about-us/'>About Us</Link></li>
					<li className={pn === '/referrals/' ? 'active' : ''}><Link to='/referrals/'>Referrals</Link></li>
					<li className={pn === '/contact-us/' ? 'active' : ''}><Link to='/contact-us/'>Contact Us</Link></li>
				</ul>
			</div>
		</div>
	);

	return (
		<div id="header" className="header">
			<div id="header-box" className="header-box">
				<div className="header-item header-item--logo XXXoutlines">
					<Link to='/'><Logo /></Link>
				</div>
				<div className="header-item header-item-menu">
					<HamburgerMenu
						isMenuOpen={isMenuOpen}
						menuContent={menuContent}
						handleMenuToggle={handleMenuToggle}
						/>
					<NavBar
						menuContent={menuContent}
						/>
				</div>

			</div>
		</div>
	);
}
export default withRouter(Header);