import React from 'react';
import { Helmet } from "react-helmet-async";
import Banner from '../../components/Banner/Banner.jsx';
import CallToAction from '../../components/CallToAction/CallToAction.js'
import './AboutUs.scss';

function AboutUs() {
	return (
		<div className="main">
			<Helmet>
				<title>About Us | Stephen Woolley Occupational Therapy</title>
				<meta name="description" content="Melbourne Occupational Therapy | Stephen Woolley" />
				<link rel="canonical" href="https://stephenwoolley/about-us/" />
			</Helmet>
			<Banner name="head" className="neutral-ultra-light-bg" />

			<section className="solid-white pad-top-50, pad-base-80">
				<div className="section-heading-box bound container">
					<h1>About Stephen Woolley Occupational Therapy</h1>
					<h2 className="bold-pink">When you need <span>an Occupational Therapist who cares</span> about detail</h2>
				</div>
				<div className="container">
					<div className="container-left about-us-img" />
					<div className="container-right philosophy-txt">
						<p>Established in 2013, Stephen Woolley Occupational Therapy, has been providing reliable medico legal services, employment services, and private occupational therapy services for various law firms, employers, and individuals. </p>
						<p>We are a boutique occupational therapy firm which recognises the importance of providing highly skilled OT services to ensure quality is consistent and to the highest level. </p>
						<p>With over 17 years experience in the public and private health care sectors, we can provide expert services for various, with assessments and reports delivered in a timely manner. </p>
						<p>We understand our clients rely on us to carry out a detailed occupational therapy assessment in order to provide a reliable and clinically justifiable report which assists our clients make important decisions.</p>
						<p>At Stephen Woolley Occupational Therapy, we maintain an ongoing professional development program, in both the Occupational Therapy and Nursing sectors, to ensure we are best in field and always using the latest industry expertise to our clients' advantage.</p>
						<p>We provide occupational therapy services not only in the Melbourne area, but also rural Victoria, and provide medico legal assessments Australia wide. </p>

						<CallToAction ctaLabel={["Make a start by talking to us"]} className="cta--left cta--gold-blue" ctaDestination='/contact-us/'/>
					</div>
				</div>
			</section>

		</div>
	);
}

export default AboutUs;