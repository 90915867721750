import React, { useState, useEffect } from "react";
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
  InfoWindow
} from "react-google-maps";
import Icon from '../Icon/Icon';
// import * as locationData from "./map-locations.json";
import mapStyles from "./mapStylesGreyBlue";					//  >> https://mapstyle.withgoogle.com/
import "./LHMap.scss";

const locations = [
    {
      "type": "Mappable",
      "location": {
        "SPOT_ID": 1,
        "FACILITYID": 1,
		"NAME": "Charles Street Clinic",
		"SUBTLEHEADING": "(by appointment only)",
		"ADDRESS": "89 Charles Street, Seddon VIC 3011",
		"PHONE": "(03) 9687 7153",
		"EMAIL": "info@stephenwoolley.com.au",
		"OPEN": null
      },
      "geometry": {
        "type": "Point",
        "coordinates": [144.891110, -37.806780 ]
      }
    }
];



function Map() {
  const [selectedLocation, setSelectedLocation] = useState(null);

  useEffect(() => {
    const listener = e => {
      if (e.key === "Escape") {
        setSelectedLocation(null);
      }
    };
    window.addEventListener("keydown", listener);

    return () => {
      window.removeEventListener("keydown", listener);
    };
  }, []);

  return (
    <GoogleMap
      defaultZoom={12}
      defaultCenter={{ lat: -37.82, lng: 144.891110 }}
	  defaultOptions={{ styles: mapStyles, scrollwheel: true }}

    >
      {locations.map((spot,keyID) => (
		<Marker
			key={keyID}
			position={{
				lat: spot.geometry.coordinates[1],
				lng: spot.geometry.coordinates[0]
			}}
			onClick={() => {
				setSelectedLocation(spot);
			}}
			icon={{
				url: `/icon-map-marker.svg`,
				scaledSize: new window.google.maps.Size(35, 35)
			}}
		/>
      ))}

		{ selectedLocation && (
			<InfoWindow
				onCloseClick = { () => {
					setSelectedLocation(null);
				}}
				position = { {
					lat: selectedLocation.geometry.coordinates[1],
					lng: selectedLocation.geometry.coordinates[0]
				}}
				>
				<div>
					<h2>{selectedLocation.location.NAME}</h2>
					{ selectedLocation.location.SUBTLEHEADING && <h3>{selectedLocation.location.SUBTLEHEADING}</h3>}
					<div className="gmap-icon-text-box">
						<Icon type="building" />
						<p><a target="_exSWW" href={`https://goo.gl/maps/TrDQDwFegcRpYZrM8`}>{selectedLocation.location.ADDRESS}</a></p>
					</div>
					<div className="gmap-icon-text-box">
						<Icon type="phone" />
						<p><a href={`tel:${selectedLocation.location.PHONE}`}>{selectedLocation.location.PHONE}</a></p>
					</div>
					<div className="gmap-icon-text-box">
						<Icon type="email" />
						<p><a href={`mailto:${selectedLocation.location.EMAIL}`}>{selectedLocation.location.EMAIL}</a></p>
					</div>
				</div>
			</InfoWindow>
		)}
		</GoogleMap>
	);
}

const MapWrapped = withScriptjs(withGoogleMap(Map));

export default function LHMap() {
  return (
    <div style={{ width: "100%", height: "350px" }}>
      <MapWrapped
        googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${
          process.env.REACT_APP_GOOGLE_KEY
        }`}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `100%` }} />}
        mapElement={<div style={{ height: `100%` }} />}
      />
    </div>
  );
}