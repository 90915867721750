import React from "react";
import Carousel from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
//import BreakpointContext from '../../contexts/BreakpointContext'

import "./Associations.scss";

//import Image1 from "../../img/_01.png";
//import Image6 from "../../img/_06.png";
//import Comcare from "../../svg/_ap_comcare.svg";
import ComcareGov from "../../svg/_ap_comcare_gov.svg";
import Workhab from "../../svg/_ap_workhab.svg";
import Medicare from "../../svg/_ap_medicare.svg";
import Worksafe from "../../svg/_ap_worksafe.svg";
import Ahpra from "../../svg/_ap_ahpra.svg";
import OccupationalTherapyAustralia from "../../svg/_ap_occupational-therapy-australia.svg";
import Ndis from "../../svg/_ap_ndis.svg";

const CarouselWrap = (props) => {
	//const { label, level } = useContext(BreakpointContext);
	//console.log('CONTEXT: ',level,'. ',label);
	/*	'mobile-s': 10,
		'mobile-m': 22,
		'mobile-l': 30,
		'mobile-h': 35,
		'mobile-landscape': 40,
		'tablet': 50,
		'desktop-s': 60,
		'desktop-ms': 65,
		'desktop-m': 70,
		'desktop-l': 80,
		'wide': 90,
	*/
	//console.log('CAROUSEL', props.breakpoint);

	let spp = 5;
	if(props.breakpoint.level < 80) {
		spp = 3;
	}
	if(props.breakpoint.level < 40) {
		spp = 1;
	}

	return (
	<div className="associations-carousel carousel-box">
		<Carousel
			centered
			infinite
			slidesPerPage={spp}
			autoPlay={5000}
			animationSpeed={2000}
			>
			<div className="carousel-item"><a target="_exSWW" href="http://www.workhab.com/" alt="WorkHab Website"><img src={Workhab} alt="WorkHab Logo" /></a></div>
			<div className="carousel-item"><a target="_exSWW" href="https://www.comcare.gov.au/" alt="ComCare Website"><img src={ComcareGov} alt="ComCare Logo" /></a></div>
			<div className="carousel-item"><a target="_exSWW" href="https://www.humanservices.gov.au/individuals/medicare" alt="Medicare Website"><img src={Medicare} alt="" /></a></div>
			<div className="carousel-item"><a target="_exSWW" href="https://www.worksafe.vic.gov.au/" alt="WorkSafe Vic Website"><img src={Worksafe} alt="WorkSafe Logo" /></a></div>
			<div className="carousel-item"><a target="_exSWW" href="https://www.ahpra.gov.au/" alt="Check our AHPRA Registraton here"><img src={Ahpra} alt="Ahpra Logo" /></a></div>
			<div className="carousel-item"><a target="_exSWW" href="https://otaus.com.au/" alt="Occupational Therapy Australia Website"><img src={OccupationalTherapyAustralia} alt="Occupational Therapy Australia Logo" /></a></div>
			<div className="carousel-item"><a target="_exSWW" href="https://www.ndis.gov.au/" alt="National Disability Insurance Scheme Website"><img src={Ndis} alt="National Disability Insurance Scheme Logo" /></a></div>
		</Carousel>
	</div>
	)
};

export default CarouselWrap;