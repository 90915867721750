import React from 'react';
import { Link } from 'react-router-dom';
import Icon from '../Icon/Icon';
import './ButtonLink.scss';

function ButtonLink(props) {

	const {
		className,
		to,
		icon,
		target,
		// loading,
		// onClick,
		// disabled,
		children
	} = props;


	const classes = [ 'button-link' ];
	if (className) {
		classes.push(className);
	}

	// It is intended to be an external link, don't use react-router and put the target in
	if (/^(https?:\/\/|tel:)/.test(to)) {
		return (
			<a href={to} className={classes.join(' ')} target={target}>
				{icon && <Icon type={icon} /> }
				{children ? children : 'Click Here'}
			</a>
		)
	}

	// Finally, it is an internal link if not alreay returned so use react-router and ignore target attribute
	return (
		<Link to={to} className={classes.join(' ')}	>
			{icon && <Icon type={icon} /> }
			{children ? children : 'Click Here'}
		</Link>
	);




}
export default ButtonLink;