import React, { Fragment } from "react";

import CallToAction from '../CallToAction/CallToAction.js'
import './Banner.scss';

const getBannerContent = (props) => {

	const classes = [ 'banner mt-0' ];
	if (props.className) {
		classes.push(props.className);
	}

	if(props.name === 'main') {
		classes.push('banner--billboard billboard--expert-right');
		return (
			<Fragment>
				<div className="teaser-box">
					<div className="teaser">

						<h2>Melbourne's Trusted<br/>Medico Legal Expert</h2>
						<h3>Effective & Reliable</h3>
						<p>
					{/*
						Stephen Woolley has over 17 years'<br className="forced"/>
						experience delivering quality, reliable<br className="forced"/>
						Medico Legal & Employment Services
						*/}

						<span className="forced">Stephen Woolley has over 17 years'</span>
						<span className="forced">experience in the Medico-Legal</span>
						and Employment Services fields

						</p>
						<CallToAction className="cta--right" />
					</div>
				</div>

				<section className="section-pusher"></section>
				<section className={classes.join(' ')}>
					<div className="divider-down--top">
						<svg width="100%" height="100px" viewBox="0 0 1280 140" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
							<g>
								<path className="divider-down--top--svg" d="M640 139 L0 0	h1280		L640 139z"/>
								<path className="divider-down--top--svg" d="M640 139 L0 42	v-42 h1280 v42 l-640 97z" fillOpacity=".5"/>
							</g>
						</svg>
					</div>
<div className="floater"></div>
					<div className="divider-down--bottom">
						<svg width="100%" height="100px" viewBox="0 0 1280 140" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
							<g fill="#ffffff">
								<path d="M640 139L0 0v140h1280V0L640 139z" fillOpacity=".5"/>
								<path d="M640 139L0 42v98h1280V42l-640 97z"/>
							</g>
						</svg>
					</div>
				</section>
			</Fragment>
		);
	} else {
		classes.push('banner--blank');
		return (
			<section className={classes.join(' ')}> </section>
		);
	}
}

function Banner(props){

	const content = getBannerContent(props);

	return content;
}
export default Banner;