import React, { useEffect, useState } from "react";

import './Scroller.scss';

export default function Scroller() {
	const [scrollCount, setScrollCount] = useState(0);

	useEffect(() => {
		try {
			window.scroll({
				top: 0,
				left: 0,
				behavior: 'smooth',
			});
		} catch (error) {
			window.scrollTo(0, 0);
		}
	}, [scrollCount]);

	return (
		<div>
			<div className="scroll-to-top-button" onClick={() => setScrollCount(scrollCount + 1)} alt="Click to scroll to top">
				<svg width="100%" height="100%" viewBox="0 0 30 30" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg">
					<g><path className="scroll-to-top--svg" d="M15 4 L25 22 h-20 L15 4z"></path></g>
				</svg>
			</div>
		</div>
	);
}