import React from 'react';
import { Helmet } from "react-helmet-async";
import { Link } from 'react-router-dom'
import Banner from '../../components/Banner/Banner.jsx';
import './FourZeroFour.scss';

function PrivacyPolicy() {
	return (
		<div className="main">
			<Helmet>
				<title>Oops! | Stephen Woolley Occupational Therapy</title>
        		<meta name="description" content="Medico Legal and Workplace Services Occupational Therapy" />
                <link rel="canonical" href="https://stephenwoolley.com.au/404/" />
			</Helmet>

			<Banner name="head" className="neutral-ultra-light-bg" />



			<section className="white-bg collapse">
				<div className="section-heading-box bound container">
					<h1>404: Page Not Found</h1>
				</div>
				<div className="container four-zero-four-message">
					<p>We had a look but we couldn't find the page you requested.</p>
					<p>Try the navigation at the top of the page or <Link to="/contact-us/">contact us</Link> if you need help.</p>
				</div>
			</section>


		</div>
	);
}

export default PrivacyPolicy;