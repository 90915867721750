import React from 'react';

import './Icon.scss';

import { ReactComponent as Car } from '../../svg/icon-car.svg';
import { ReactComponent as Workplace } from '../../svg/icon-workplace.svg';
import { ReactComponent as DocumentStack } from '../../svg/icon-document-stack.svg';
import { ReactComponent as MapMarker } from '../../svg/icon-map-marker.svg';
import { ReactComponent as Comment } from '../../svg/icon-comment.svg';
import { ReactComponent as LinkedIn } from '../../svg/icon-linkedin.svg';
import { ReactComponent as LinkChain } from '../../svg/icon-link-chain.svg';
import { ReactComponent as LinkExternal } from '../../svg/icon-link-external.svg';
import { ReactComponent as Sitemap } from '../../svg/icon-sitemap.svg';
import { ReactComponent as Privacy } from '../../svg/icon-user-secret.svg';
import { ReactComponent as Email } from '../../svg/icon-envelope.svg';
import { ReactComponent as EmailOL } from '../../svg/icon-envelope-ol.svg';
import { ReactComponent as Phone } from '../../svg/icon-phone.svg';
import { ReactComponent as PhoneOL } from '../../svg/icon-phone-ol.svg';
import { ReactComponent as Building } from '../../svg/icon-building.svg';
import { ReactComponent as BuildingOL } from '../../svg/icon-building-ol.svg';
import { ReactComponent as Facebook } from '../../svg/icon-facebook.svg';


function Icon(props) {

	const {
		className,
		type
		// ...otherProps
	} = props;

	const classes = [ 'icon' ];
	if (className) {
		classes.push(className);
	}
	if (type) {
		classes.push(`type-${type}`);
	}

	return (
		<div className={classes.join(' ')}>
            {type === "car" && <Car />}
            {type === "workplace" && <Workplace />}
            {type === "document-stack" && <DocumentStack />}
            {type === "map-marker" && <MapMarker />}
            {type === "comment" && <Comment />}
            {type === "linked-in" && <LinkedIn />}
            {type === "link-chain" && <LinkChain />}
            {type === "link-external" && <LinkExternal />}
            {type === "sitemap" && <Sitemap />}
            {type === "privacy" && <Privacy />}
            {type === "email" && <Email />}
            {type === "email-ol" && <EmailOL />}
            {type === "phone" && <Phone />}
            {type === "phone-ol" && <PhoneOL />}
            {type === "building" && <Building />}
            {type === "building-ol" && <BuildingOL />}
            {type === "facebook" && <Facebook />}
		</div>
	);




}
export default Icon;