import React, { useState, useLayoutEffect, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import * as utils from '../utils/general.js';

import './App.scss';

import Header				from '../components/Header/Header';
import Home					from '../containers/Home/Home';
import AboutUs				from '../containers/AboutUs/AboutUs';
import ContactUs			from '../containers/ContactUs/ContactUs';
import Services				from '../containers/Services/Services';
import MedicoLegal			from '../containers/MedicoLegal/MedicoLegal';
import EmploymentServices	from '../containers/EmploymentServices/EmploymentServices';
import Referrals			from '../containers/Referrals/Referrals';
import PrivacyPolicy		from '../containers/PrivacyPolicy/PrivacyPolicy';
import Sitemap				from '../containers/Sitemap/Sitemap';
import FourZeroFour			from '../containers/FourZeroFour/FourZeroFour';

import Footer 				from '../components/Footer/Footer';
import Scroller 			from '../components/Scroller/Scroller.js';

function App() {

	useLayoutEffect(() => {
		window.addEventListener('scroll', handleHeader);
		return () => window.removeEventListener('scroll', handleHeader);
	});
	useLayoutEffect(() => {
		window.addEventListener('resize', resizeHandler);							// Run the rezise through a throttle
		return () => window.removeEventListener('resize', resizeHandler);
	});
	useEffect(() => {																// This is an onLoad to determine header if on mobile etc.
		handleHeader();
		handleBreakpoint();
	}, []);

	const [headerIsSmall, setHeaderIsSmall] = useState(false);
	const [pageScrolled, setPageScrolled] = useState(false);
	const [breakpoint, setBreakpoint] = useState({ level: 70, label: 'desktop-m'});

	const resizeHandler = utils.throttle(() => {
		window.dispatchEvent(new CustomEvent('RESIZE'));							// as we have hijacked the resize event - ignoring ultra short repeats, (re) trigger it manually
		handleHeader();
		handleBreakpoint();
	});


	const handleBreakpoint = () => {
		setBreakpoint({
			label: utils.getBreakpoint(),
			level: utils.breakpoints[utils.getBreakpoint()]
		});
	}

	const handleHeader = () => {
		// TASK: change to use refs
		if ( window.pageYOffset < 5 ) {
			document.getElementById('header').classList.remove('header--shadow');
		}
		if ( window.pageYOffset >= 5 ) {
			document.getElementById('header').classList.add('header--shadow');
		}
		if ( window.pageYOffset < 100 ) {
			setPageScrolled(false);
		}
		if ( window.pageYOffset >= 100 ) {
			setPageScrolled(true);
		}
		if ( window.pageYOffset >= 70 || window.innerWidth < 480 ) {
			setHeaderIsSmall(true);
		}
		if ( window.pageYOffset < 70 && window.innerWidth >= 480 ) {
			setHeaderIsSmall(false);
		}
	}

	const classes = [ 'App' ];
	if (headerIsSmall) {
		classes.push('header--small');
	}
	if (pageScrolled) {
		classes.push('page--scrolled');
	}

	return (
		<div className={classes.join(' ')} id="app">
			{/* <BreakpointProvider value={{label: 'one', level: 3}}> */}
				<Header />
				<Switch>
					<Route
						exact
						path="/"
						render={routeProps => {
							return <Home {...routeProps} breakpoint={breakpoint} />; }
						}
						/>
					<Route path='/services/' component={Services}/>
					<Route path='/referrals/' component={Referrals}/>
					<Route path='/about-us/' component={AboutUs}/>
					<Route path='/medico-legal-services/' component={MedicoLegal}/>
					<Route path='/employment-services/' component={EmploymentServices}/>
					<Route path='/contact-us/' component={ContactUs}/>
					<Route path='/privacy-policy/' component={PrivacyPolicy}/>
					<Route path='/sitemap/' component={Sitemap}/>
					<Route path='*' component={FourZeroFour}/>
				</Switch>
				<Footer />
				<Scroller />
			{/* </BreakpointProvider> */}
		</div>
	);
}

export default App;
