import React from 'react';
import { Helmet } from "react-helmet-async";
import Banner from '../../components/Banner/Banner.jsx';
//import './PrivacyPolicy.css';

function PrivacyPolicy() {
	return (
		<div className="main">
			<Helmet>
				<title>Privacy Policy | Stephen Woolley Occupational Therapy</title>
				<meta name="description" content="Melbourne Occupational Therapy | Stephen Woolley" />
				<link rel="canonical" href="https://stephenwoolley.com.au/privacy-policy/" />
			</Helmet>
			<Banner name="head" className="neutral-ultra-light-bg" />

			{/* <div className="container-right cards">
						<h4 className="breaker">In Depth <span>Medico Legal</span> Reports</h4>
						<p>The medico legal report considers (due to their compensable injuries) an individual’s past, present, and future personal, domestic, garden and handyman care requirements. The report also considers current and future home modifications, equipment requirements, and rehabilitation/treatment needs. It has a detailed functional section based on observations of the client completing various domestic tasks, as well as information (observations and formal assessments) on any physical, sensory, cognitive, and psychosocial deficits. It has a detailed costing section for past and future needs, home modifications, equipment needs including replacement of equipment, and costings for rehabilitation/treatment needs (within the Assessor’s area of expertise).</p>
						<p>We have experience of completing reports for various claims, plaintiff and defendants, due to various injuries sustained by:</p>
						<CallToAction ctaLabel={["Ask us how we can help you"]} className=" mt-20 cta--gold-blue" ctaDestination='/contact-us/'/>
					</div> */}


			<section className="white-bg collapse">
				<div className="section-heading-box bound container">
					<h1>Privacy Policy & Disclaimer</h1>
					{/* <h2>Leveraging years of <span>Experience</span> to produce <span>high quality and realiable</span> Medico-Legal Reports.</h2> */}
				</div>
				<div className="container with-box-heading">

					<div className="container-left">
						<h4 className="breaker"><span>General</span></h4>
						<p>Reasonable effort is made to maintain the currency and accuracy of the information on stephenwoolley.com.au. However, before relying on any information users should carefully evaluate its accuracy, currency, completeness and relevance for their circumstance and should obtain appropriate professional advice. The use of the information that is provided on this site is the sole responsibility of the user. If you have concerns about the information on this web site you should contact info@stephenwoolley.com.au directly.</p>
						<p>Links to other sites from this web site are provided for the users' convenience. Stephen Woolley Pty Ltd does not endorse these sites and is not responsible for the information on these sites or the use made of this information.</p>
						<p>The views expressed in the papers and reports that are provided on this site are those of the Author. Stephen Woolley Pty Ltd does not necessarily endorse the views of a particular author or authenticate the accuracy of the information provided. The use a reader makes of the information provided in these papers is the reader's sole responsibility.</p>
						<p>The listing of any person, organisation, product or service on this site does not constitute, in any way, any form of endorsement by Stephen Woolley Pty Ltd of that person, organisation, product or service.</p>

						<h4 className="breaker">Security</h4>
						<p>Although security controls are employed to protect unauthorised access to this web site, users should be aware that the World Wide Web (the web) is an insecure public network. In accessing the web, there is the potential risk that a user's transactions are being viewed, intercepted or modified by third parties, or that files that the user downloads may contain computer viruses, disabling codes, worms or other defects.</p>
						<p>Stephen Woolley Pty Ltd accepts no liability for any interference with, or damage to, a user's computer system, software or data occurring in connection with or relating to this web site or its use. Users are encouraged to take appropriate and adequate precautions to ensure that whatever is selected from this site is free of viruses or other contaminations that may interfere with or damage the user's computer system, software or data.</p>

						<h4 className="breaker">Linking to this site</h4>
						<p>You may link to this web site at your full expense and responsibility. You must not:</p>

						<p>Alter any of the web-site's contents; or</p>

						<p>Frame or reformat the files, pages, images, information and materials from this web site on any other web site.</p>

						<p>Stephen Woolley Occupational Therapy Pty Ltd reserves the right to prevent linking by giving notice.</p>

						<p>You are not permitted to re-transmit, distribute or commercialise the information or material without seeking prior written approval from Stephen Woolley Occupational Therapy Pty Ltd. You are not permitted to use this website to sell a product or to increase traffic to your web site.</p>

						<h4 className="breaker">Information collected</h4>
						<p>stephenwoolley.com.au may make and keep a record of your visit and may log information for statistical purposes including, but not limited to:</p>

						<ul>
							<li>The user's IP address;</li>
							<li>The date and time of the visit to the site;</li>
							<li>The pages accessed and documents downloaded; and</li>
							<li>The type of browser used.</li>
						</ul>

						<h4 className="breaker">Access to information collected</h4>
						<p>No attempt will be made to identify users or their browsing activities except in the unlikely event of an investigation, where a law enforcement agency may exercise a warrant to inspect the Internet service provider's logs.</p>

						<h4 className="breaker">Email management</h4>
						<p>stephenwoolley.com.au will only record your e-mail address if you send us a message. It will only be used for the purpose for which you have provided it and will not be added to a mailing list. We will not use your e-mail address for any other purpose, and will not disclose it without your consent.</p>

						<p>Please note that when you contact us via e-mail the content of your e-mail as it is transferred across the Internet may not be secure. If you have a concern about the security of your message you are advised to contact us directly or by phone or post.</p>

						<h4 className="breaker">Cookies</h4>
						<p>Cookies are not used to collect personal information. Cookies may be used to collect usage data to assist us in improving our service.</p>

						<h4 className="breaker">Further enquiries</h4>
						<p>If you have enquiries regarding the privacy of this site please contact info@stephenwoolley.com.au or write to me at:</p>

						<p>Stephen Woolley
						<br />Director / Occupational Therapist
						<br />Stephen Woolley Occupational Therapy Pty Ltd
						<br />P.O. Box 364
						<br />SEDDON VIC 3011</p>

					</div>

				</div>
			</section>


		</div>
	);
}

export default PrivacyPolicy;