import React from 'react';
import { Helmet } from "react-helmet-async";
import Banner from '../../components/Banner/Banner.jsx';
import Cards from '../../components/Cards/Cards.js';

function EmploymentServices() {

	const cards = [
		{	icon:		"user-check",
			heading:	"Work Capacity/Functional Capacity Evaluations",
			para:		"These standardised assessments help determine a client’s current work capacity for completing their pre-injury role or alternative work role."
		},
		{	icon:		"ergonomic",
			heading:	"Workstation/Ergonomic Assessments",
			para:		"These assessments assist to educate an individual on how to correctly set-up their workstations. Reports include recommendations, as well as equipment details i.e. product, code, cost, and supplier."
		},
		{	icon:		"medical-assessment",
			heading:	"Pre-Employment Assessments",
			para:		"Functional and medical assessments to ensure an applicant is fit to carry out the inherent requirements of the role. This may involve basic medical assessment such as height, weight, blood pressure, and pulse; visual acuity (near and distance vision); musculoskeletal assessment; urinalysis; range of movement assessment; spirometry; audiogram; functional assessment; endurance testing; grip strength; drug and alcohol screening (approved assessors); and/or manual handling assessment."
		},
		{	icon:		"open-box",
			heading:	"Manual Handling Training",
			para:		"This training session assists to educate individuals on correct manual handling techniques, educates individuals on basic anatomy of the spine, common injuries in the workplace, ways to reduce the risk of injury, equipment which may assist to promote back care, different lifting techniques, ways to facilitate postural adjustments, and exercises/stretches we can perform in the workplace. A workplace visit is carried out prior to the training session to ensure the session is tailored for the target audience and work duties performed."
		},
		{	icon:		"monitor",
			heading:	"Ergonomic Training",
			para:		"This training session assists to educate individuals on correct ergonomic set-up, common injuries in the workplace, ways to reduce the risk of injury, equipment which may assist promote correct ergonomics, ways to facilitate postural adjustments, and exercises/stretches we can perform in the workplace."
		},
		{	icon:		"medical-notes",
			heading:	"Activities of Daily Living Assessments",
			para:		"The report assists an employer and/or insurer to understand the care needs a claimant may have following an injury, including claims involving the TAC and WorkCover"
		}
	];

	return (
		<div className="main">
			<Helmet>
				<title>Employment Services | Stephen Woolley Occupational Therapy</title>
				<meta name="description" content="Melbourne Occupational Therapy | Stephen Woolley" />
				<link rel="canonical" href="https://stephenwoolley/employment-services/" />
			</Helmet>
			<Banner name="head" className="neutral-ultra-light-bg" />

			<section className="white-bg collapse">
				<div className="section-heading-box bound container">
					<h1>Employment Services</h1>
					<h2>Leveraging years of <span>Experience</span> to produce <span>reliable and detailed</span> Reports and Assessments.</h2>
				</div>
				<div className="container with-box-heading">
					<p>We have extensive case management experience within the occupational rehabilitation setting, allowing us to provide an array of in-depth employment services. We have an understanding of employer obligations and the necessity to safeguard businesses from work related injuries and claims. We have an understanding of the various target audiences and tailor our presentations and assessments to ensure effective learning occurs translating to long-term solutions for a business and its employees.</p>
				</div>
			</section>

			<section className="white-bg ">
				<div className="section-heading-box bound container">
					<h3>Experience delivering an array of workplace and employment services</h3>
					<h2>Helping employers have confidence in their workplace setup and employee capacity and wellbeing.</h2>
				</div>
				<div className="container with-box-heading">
					<Cards cardDeck={cards} />
				</div>
			</section>
		</div>
	);
}

export default EmploymentServices;