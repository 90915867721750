import React from 'react';
import { Helmet } from "react-helmet-async";
import Banner from '../../components/Banner/Banner.jsx';
import Cards from '../../components/Cards/Cards.js';
import CallToAction from '../../components/CallToAction/CallToAction.js'


function MedicoLegal() {


	const cards = [
		{	icon:		"store",
			heading:	"Workplace Injuries",
			para:		"Reports which outline the care requirements for individuals who have injuries due to workplace accidents, including claims involving various insurers."
		},
		{	icon:		"car-crash",
			heading:	"Motor Vehicle Injuries",
			para:		"Reports which outline the care requirements for individuals who have injuries due to a motor vehicle accident, including claims involving various insurers and TAC."
		},
		{	icon:		"ambulance",
			heading:	"Medical Negligence",
			para:		"Reports which outline the care requirements for individuals who have injuries due to medical negligence, including reports to assist with Class Actions. We have also provided reports for insurers of large hospital organisations and Consultant Doctors."
		},
		{	icon:		"injury",
			heading:	"Personal Injury",
			para:		"Reports which outline the care requirements for individuals who have sustained injuries due to negligence of local councils, State Departments, and residential or commercial landlords (among others). We have also provided reports for insurers to assist with personal injury claims."
		},
		{	icon:		"ban",
			heading:	"Sexual Abuse claims",
			para:		"Reports which outline the physical and psychological care requirements due to past sexual abuse, including claims against Institutions & State Departments."
		},
		{	icon:		"hospital",
			heading:	"End of Life/Palliative Care Claims",
			para:		"Reports which outline the care requirements for individuals with a palliative care diagnosis due to negligence."
		},
		{	icon:		"court",
			heading:	"Tribunal Cases",
			para:		"Reports which assist an individual with Administrative Appeals Tribunal (AAT) and/or Victorian Civil and Administrative Tribunal (VCAT) e.g. clients who have underfunded NDIS plans or have had difficulty accessing life insurance policies."
		},
		{	icon:		"health-check",
			heading:	"Psychiatric conditions",
			para:		"Reports which outline the psychological care requirements due to past exposure to significantly stressful situations and environments, including claims involving Australian government departments and psychiatric injuries sustained in the course of employment."
		},
		{	icon:		"book",
			heading:	"Wills and Estate Issues/Needs Assessments",
			para:		"Reports which outline the care required to support a person, of whom a deceased had responsibility to make provision for."
		},
		{	icon:		"user-check",
			heading:	"Work Capacity/Functional Capacity Evaluations",
			para:		"These standardised assessments help determine a client’s current work capacity for completing their pre-injury role or alternative work role."
		},
		{	icon:		"wind",
			heading:	"Respiratory/Lung Disease",
			para:		"Reports which outline the care requirements for individuals with a chronic and progressive disease"
		}
	];

	return (
		<div className="main">
			<Helmet>
				<title>Medico Legal Services | Stephen Woolley Occupational Therapy</title>
				<meta name="description" content="Melbourne Occupational Therapy | Stephen Woolley" />
				<link rel="canonical" href="https://stephenwoolley/medico-legal-services/" />
			</Helmet>
			<Banner name="head" className="neutral-ultra-light-bg" />


			<section className="white-bg collapse">
				<div className="section-heading-box bound container">
					<h1>Medico Legal Services</h1>
					{/* <h2><span>Reliable</span> and <span>In-Depth</span> Medico-Legal Reports delivered on-time.</h2> */}
					<h2>Leveraging years of <span>Experience</span> to produce <span>high quality and reliable</span> Medico-Legal Reports.</h2>
				</div>
				<div className="container with-box-heading">

					<div className="container-left">
						<h4 className="breaker"><span>Hospital</span> and <span>Community</span> OT Experience</h4>
						<p>Stephen has experience as a clinician (Occupational Therapy) in hospital, community, and private practice settings where he worked in the areas of neurology, orthopaedics, acquired brain injury, general medical, aged care, musculoskeletal injuries including upper limb injuries, spinal injuries, infectious disease, emergency department, secondary and tertiary rehabilitation, occupational rehabilitation, and psychological conditions.</p>
						<h4 className="breaker"><span>Clinical</span> experience as a <span>Registered Nurse</span></h4>
						<p>Stephen also relies on clinical experience working in aged care and acute psychiatric services as a Registered Nurse. During this time, he has provided nursing care to clients and their families. This has involved developing appropriate care plans to ensure a client’s care needs are fully met. It also involves maintaining effective communication with staff to ensure changes in their functional status is reflected in the care they receive.</p>
					</div>
					<div className="container-right">
						<h4 className="breaker">In Depth <span>Medico Legal</span> Reports</h4>
						<p>The medico legal report considers an individual’s care requirements, home modifications, equipment requirements,  rehabilitation/treatment needs, and detailed costs due to an injury. Recommendations are based on physical, sensory, cognitive, and psychosocial deficits. We have a broad range of experience completing reports for various claims, including plaintiff and defendants, due to a variety of injuries sustained.</p>
						<CallToAction ctaLabel={["Ask us how we can help you"]} className=" mt-20 cta--gold-blue" ctaDestination='/contact-us/'/>
					</div>

				</div>
			</section>

			<section className="white-bg ">
				<div className="section-heading-box bound container">
					<h3>Expertise across the range of Medico Legal requirements</h3>
					<h2>We can help you navigate the complex field of <span>Medico-Legal</span> requirements.</h2>
				</div>
				<div className="container with-box-heading">
					<Cards cardDeck={cards} />
				</div>
			</section>
		</div>
	);
}

export default MedicoLegal;