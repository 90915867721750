import React from "react";
import Carousel from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";

import "./Testimonials.scss";


const CarouselWrap = (props) => {

	return (
	<div className="testimonials-carousel carousel-box">
		<Carousel
			arrows
			centered
			infinite
			slidesPerPage={1}
			autoPlay={13000}
			animationSpeed={3000}
			>

			<div className="carousel-item">
				<p>I wasn't really sure our small business could afford a workplace assessment but Stephen showed me
					how to get the best ROI. I have two full time developers and we spend a lot of time at our screens.
					Stephen hepled us source individually suited office chairs and setup our space so we can maintain
					focus and feel better at the end of the	day's work. It's made a huge impact on our productivity and
					staff happiness and health.</p>
				<h5>Jeff Mitchell</h5>
				<h6>Brighton</h6>
			</div>

			<div className="carousel-item">
				<p>As part of a large government organisation we occasionally have to assess staff medical claims.
					Stephen's team provided an extremely insightful and balanced assessment of a problem staff member
					allowing us to approach the situation with confidence and clarity. This brought a conclusion to the
					issue much faster, and with a fairer outcome, than otherwise was expected.</p>
				<h5>Anne Garside</h5>
				<h6>Melbourne</h6>
			</div>

			<div className="carousel-item">
				<p>Stephen provides thorough and detailed reports which have assisted our firm and Counsel when dealing with complex and detailed claims.
					His reports are well researched and considered which prove useful for negotiations.</p>
				<h5>Julian Burns</h5>
				<h6>Melbourne CBD</h6>
			</div>

			<div className="carousel-item">
				<p>Stephen has been able to carry out and provide our firm with medical legal reports in short notice.
					He is always reliable and communicates effectively to ensure our deadlines are met.
					The report quality is never compromised and proved useful in maximising our clients' claims.</p>
				<h5>Jessica Glynn</h5>
				<h6>Craigieburn VIC</h6>
			</div>

			<div className="carousel-item">
				<p>I have used various OT's over my 20-year career in personal injury.
					I have found Stephen's OT and nursing knowledge provides an advantage over other OT's and sets him apart.
					His OT and nursing skills allow him to provide more detailed reports in relation to a client's injuries and future needs.</p>
				<h5>James Grant</h5>
				<h6>Melbourne CBD</h6>
			</div>

			<div className="carousel-item">
				<p>I always know Stephen will be able to assist...
					I don't waste my time searching for an untested OT or using less experienced OTs in the medico-legal field. </p>
				<h5>Christopher Ellis</h5>
				<h6>Melbourne CBD</h6>
			</div>

			<div className="carousel-item">
				<p>They provided a thorough and practical manual handling training sessions.
					The course was well targeted to the audience.
					The lads were engaged and learnt the importance of back care. </p>
				<h5>Bob Turecek</h5>
				<h6>Campbellfield VIC</h6>
			</div>

			<div className="carousel-item">
				<p>Our business has used Stephen for many years.
					Our workers' safety is always our priority.
					Stephen completes thorough and objective functional capacity evaluations which assist our business to make, at times, difficult decisions to safeguard our business from potential work related injuries.</p>
				<h5>Sean Larkins</h5>
				<h6>Sunshine VIC</h6>
			</div>

			<div className="carousel-item">
				<p>They provide practical and easy to understand ergonomic training and workstation assessments.
					Our workers always learn the basic principles of ergonomics, ensuring good long term practices.
					The reports are great and have all the information you need if equipment is recommended.
					You never have to waste your time searching through endless equipment specifications and contacting suppliers.</p>
				<h5>Cindy Sweeny</h5>
				<h6>Clayton VIC </h6>
			</div>







		</Carousel>
	</div>
	)

};

export default CarouselWrap;