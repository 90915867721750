import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as IconBuildingOL } from "../../svg/icon-building-ol.svg";
import { ReactComponent as IconLayers } from "../../svg/icon-layers.svg";
import { ReactComponent as IconUser } from "../../svg/icon-user.svg";
import CustomLink from "../../utils/CustomLink.js";
import './Teasers.scss';

export default function Teasers(props) {

	const classes = [ 'container', 'showcase' ];
	if (props.serviceSubMenu) {
		classes.push('service-sub-menu');
	}

	const teaserItems = [
		{	key:		'medico-legal',
			coreService:true,
			icon:		"layers",
			heading:	[ <span key="1">Medico Legal</span>, " Services" ],
			linkLabel:	"Ask how we can help",
			LinkURL:	"/medico-legal-services/",
			para:		"Reliable and well researched medico-legal assessments, delivered in a timely manner. "+
						"Experienced OT clinician in hospital, private practice, and community settings "+
						"in a range of areas such as neurology, orthopaedics, acquired brain injury, aged care, and more. "
		},
		{	key:		'employement',
			coreService:true,
			icon:		"building",
			heading:	[ <span key="2">Employment</span>, " Services" ],
			linkLabel:	"Talk to us about workplace services",
			LinkURL:	"/employment-services/",
			para:		"Assessments and advice to ensure an employer's workplace is safe and productive. "+
						"We have an in-depth understanding of employers' obligations as well as their need to "+
						"safeguard both their employees and their businesses from work related injuries "+
						"and maximise staff health and potential."
		},
		{	key:		'about-us',
			coreService:false,
			icon:		"user",
			heading:	[ "Our ", <span key="3">Experience</span>, " Matters" ],
			linkLabel:	"Find our more about us",
			LinkURL:	"/about-us/",
			para:		"With over 17 years as a registered nurse and occupational therapist, Stephen Woolley has "+
						"completed medico-legal assessments for a range of injuries and claims as well as "+
						"occupational therapy specific employment services for government and private clients."

		}
	];

	const teaserContent = teaserItems.length ? teaserItems.map(teaserItem =>
		(teaserItem.coreService || !props.serviceSubMenu) &&
			<CustomLink
				tag='div'
				to={teaserItem.LinkURL}
				className="showcase-panel"
				key={teaserItem.key}
				>
				<div className="icon-box">
					{ teaserItem.icon === 'layers' &&<IconLayers /> }
					{ teaserItem.icon === 'building' &&<IconBuildingOL /> }
					{ teaserItem.icon === 'user' &&<IconUser /> }

				</div>
				<div className="text-box">
					<h2>{teaserItem.heading}</h2>
					<p>{teaserItem.para}</p>
					<Link to={teaserItem.LinkURL} >{teaserItem.linkLabel}</Link>
				</div>
			</CustomLink>
	) : '...';

	return (
		<section className="white-bg mt-0">
			<div className={classes.join(' ')}>
				{teaserContent}
			</div>
		</section>
	)
};