import React from 'react';
import { Helmet } from 'react-helmet-async';
import Banner from '../../components/Banner/Banner.jsx';
import Teasers from '../../components/Teasers/Teasers.js';
import AssociationsCarousel from '../../components/Carousels/Associations.js';
import TestimonialsCarousel from '../../components/Carousels/Testimonials.js';
import Logo from '../../components/Logo';
import CallToAction from '../../components/CallToAction/CallToAction.js'
import './Home.scss';

function Home(props) {

	return (
		<div className="main">
			<Helmet>
				<title>Medico Legal & Workplace Assessments | Stephen Woolley OT</title>
				<meta name="description" content="Melbourne Occupational Therapy services for Medico Legal, Personal Injury, Motor Vehicle Accidents, etc. Our Employment Services include Capacity Evaluations, Workplace Assessments and more." />
				<link rel="canonical" href="https://stephenwoolley.com.au" />
			</Helmet>
			<Banner name="main" className="" />

			<Teasers />

			<section className="key-bg">
				<div className="container">
					<div className="container-left">
						<h4>Looking for an Occupational Therapist in Melbourne?</h4>
						<h1>Stephen Woolley Occupational Therapy Melbourne</h1>
						<p>We can provide expert medico legal reports for a range of requirements including: personal injury,
							motor vehicle accidents, workplace injury, sexual abuse claims, medical negligence, lung and respiratory disease, end of life/palliative care, needs assessment
							for wills and estate claims, and psychological conditions. We have provided medico-legal
							assessments for both plaintiff and defendants and have completed assessments and reports in Victoria, Australian Capital
							Territory, New South Wales, Western Australia, and Queensland. Our Principal, Stephen Woolley, also has experience in
							providing evidence to the court as an expert witness. </p>
						<p>Additionally, we provide in-depth employment services such as workstation and ergonomic assessments, functional capacity
							evaluations, fitness to work/work capacity assessments, manual handling training, ergonomic training, workplace assessments
							and task analysis, and pre-employment medicals.</p>
						<p>We have over 17 years experience in the public and private health care sectors servicing our clients in Melbourne and throughout Australia.</p>
					</div>
					<div className="container-right">
						<Logo type="full" className="reversed brand-vertical home-panel" />
						<CallToAction className="cta--gold-blue" />
					</div>
				</div>
			</section>

			<section className="solid-white">
				<div className="container">
					<div className="container-left philosophy-img" />
					<div className="container-right philosophy-txt">
						{/* <h4>When you need <span>an OT who cares</span> about detail</h4> */}
						<h1>Our Philosophy</h1>
						<p>We understand our clients rely on us to carry out a detailed occupational therapy assessment in order to provide a reliable and clinically justifiable report which assists our clients court proceedings. </p>
						<p>We understand our clients require services to be delivered in a timely manner to ensure clients can receive meaningful outcomes as soon as possible.</p>
						<p>From Lawyers requiring accurate and meaningful medico legal reports to employers wanting confidence in their staff capacity and workplace environment.</p>
						<p>At Stephen Woolley Occupational Therapy, we maintain an ongoing professional development program, in both the Occupational Therapy and Nursing sectors, to ensure we are best in field and always using the latest industry expertise to our clients' advantage.</p>
						<CallToAction ctaLabel={["Find out more about us"]} className="cta--left cta--gold-blue" ctaDestination='/about-us/'/>
					</div>
				</div>
			</section>

			<section className="neutral-ultra-light-bg">
				<div className="section-heading-box bound ">
					<h3>We Are Proud of the Positive Impact We Have on Our Clients</h3>
					<h4>We genuinely care about providing <span>better outcomes for everyone</span> we work with.</h4>
				</div>
				<div className="container">
					<TestimonialsCarousel breakpoint={props.breakpoint} />
				</div>
			</section>

			<section className="white-bg">
				<div className="section-heading-box bound container">
					<h3>Staying Current Is More Than Just Being Industry Approved</h3>
					<h4>Always working to exceed industry standards and <span>provide superior service</span> to our clients.</h4>
					<p>We are registered with <a target="_exSWW" href="https://www.otaus.com.au">Occupational Therapy Australia (OTA)</a> and our principal,
						Stephen Woolley, is also a <a target="_exSWW" href="https://www.ahpra.gov.au/Registration/Registers-of-Practitioners.aspx?q=NMW0001452028&t=XKYF8lmYCHacOqozCts8">Registered Nurse (AHPRA)</a> and VicRoads approved occupational therapy driving assessor.
						We are also registered with <a target="_exSWW" href="https://www.humanservices.gov.au/individuals/medicare">Medicare</a>, <a target="_exSWW" href="https://www.worksafe.vic.gov.au/">WorkCover</a>, <a target="_exSWW" href="https://www.tac.vic.gov.au/">Transport Accident Commission (TAC)</a>, <a target="_exSWW" href="https://swep.bhs.org.au/">State Wide Equipment Program (SWEP)</a>, and <a target="_exSWW" href="http://www.dva.gov.au/">Department of Veteran Affairs (DVA)</a>.
						We can also provide services to clients who have funding through the <a target="_exSWW" href="https://www.ndis.gov.au/participants/working-providers/find-registered-provider">National Disability Insurance Scheme (NDIS)</a>.
						</p>
				</div>
				<div className="container">
					<AssociationsCarousel breakpoint={props.breakpoint} />
				</div>
			</section>



		{/* process.env.NODE_ENV && process.env.NODE_ENV === 'development' &&
			<section className="solid-white marker">
				<div className="container">

				</div>
			</section>
		*/}

	</div>
  );
}
export default Home;